import React, { Component } from "react";
import { observer } from "mobx-react";
import { Table, Input, Button as BT, ConfigProvider } from "antd";
import { CampaignIphoneColumnTableWarranty, color } from "../../../resource";
import autobind from "autobind-decorator";
import moment from "moment";
import { GET, ABLEMEN_CAMPAIGN_GET, GETAPP, GETS } from "../../../service";
import dayjs from "dayjs";
import "react-image-lightbox/style.css";

import ExportJsonExcel from "js-export-excel";
let defaultData = [];

@observer
class CampaignIphoneScene extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isLoadingData: true,
      searchText: "",
      start_date: moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      end_date: moment()
        .endOf("month")
        .format("YYYY-MM-DD"),
      fullTextSearch: "",
      user_role:
        !!JSON.parse(localStorage.getItem("DkdL8OVPdp")) &&
        JSON.parse(localStorage.getItem("DkdL8OVPdp")).role,

      filters: {},
      visibleSendMessage: false,
      lineMessage: "",
    };
  }

  componentDidMount() {
    this.onFetchCampaign(this.state.start_date, this.state.end_date, "");
  }

  @autobind
  async onFetchCampaign(
    date_start = this.state.start_date,
    date_end = this.state.end_date,
    fullSearch
  ) {
    try {
      this.setState({ isLoadingData: true });

      let obj = {
        date_start,
        date_end,
      };
      const res = await GET(
        `${ABLEMEN_CAMPAIGN_GET}?date_start=${date_start}&date_end=${date_end}`
      );
      console.log(res, "res");
      if (res.success) {
        const result = res.result;
        const data = result.map((d, i) => {
          return {
            no: i + 1,
            key: i + 1,
            ...d,
          };
        });
        defaultData = data;
        this.setState({
          isLoadingData: false,
          data,
          excel_data: data,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoadingData: false });
    }
  }

  @autobind
  onChange(e, ref) {
    this.setState({ [ref]: e });
  }

  @autobind
  onKeyPressSearch(event, type) {
    if (event.key === "Enter") {
      if (type === "fullTextSearch") {
        this.onSearch();
      }
    }
  }

  @autobind
  onSearch() {
    const { fullTextSearch } = this.state;
    if (!fullTextSearch) {
      return this.setState({ data: defaultData });
    }

    const d = defaultData.filter((el) => {
      return Object.values(el).some((val) => {
        return val
          ? val
              .toString()
              .toLowerCase()
              .includes(fullTextSearch.toLowerCase())
          : false;
      });
    });

    this.setState({ data: d });
  }
  @autobind
  onChangeRangePicker(dates) {
    let start = moment(dates[0]).format("YYYY-MM-DD");
    let end = moment(dates[1]).format("YYYY-MM-DD");
    this.setState({
      start_date: start,
      end_date: end,
      isLoadingData: true,
    });
    this.onFetchCampaign(start, end, "");
  }

  @autobind
  onRefreshClick(dates) {
    this.onFetchCampaign();
  }
  @autobind
  downloadExcel() {
    const data_excel = this.state.data;
    let option = {};
    let datatable = [];
    let date_export = dayjs(new Date()).format("DD-MM-YYYY");

    let sheetHeader = [
      "ลำดับ",
      "รหัสสินค้า",
      "วันที่ลงทะเบียน",
      "เวลาที่ลงทะเบียน",
      "ชื่อ-นามสกุล",
      "เบอร์โทรศัพท์",
      "รุ่นมือถือ",
      "รหัสร้านค้า",
      "ร้านค้า",
    ];

    if (data_excel) {
      data_excel.map((el, i) => {
        const {
          no,
          productCode,
          createdDate,
          createdTime,
          fullName,
          phoneNumber,
          modelName,
          storeCode,
          storeName,
        } = el;

        let obj = {
          no,
          productCode,
          createdDate,
          createdTime,
          fullName,
          phoneNumber,
          modelName,
          storeCode,
          storeName,
        };
        datatable.push(obj);
      });
    }
    option.fileName = `ข้อมูลลูกค้าที่ลงทะเบียน Lucky Draw Campaign iPhone ${date_export}`;
    option.datas = [
      {
        sheetData: datatable,
        sheetName: date_export,

        sheetHeader: sheetHeader,
      },
    ];

    const toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  createMessage = (record) => {
    this.setState({
      visibleSendMessage: true,
      user_id: record.userId,
      no_warranty: record.no_warranty,
      // lineMessage: `เลขที่ประกัน: ${record.no_warranty} | `
    });
  };

  render() {
    const {
      data,
      isLoadingData,
      loading,
      fullTextSearch,
      filters,
    } = this.state;
    return (
      <div>
        {/*###################### MODAL SEND MESSAGE ######################*/}
        <h1
          style={{ textAlign: "center", fontWeight: "bold" }}
        >{`Ablemen iPhone 16 Campaign`}</h1>
        <div>
          <h2
            style={{ textAlign: "center", fontWeight: "bold" }}
          >{`จำนวนลูกค้าที่ลงทะเบียน : ${data ? data.length : 0}`}</h2>
        </div>
        <div className={"column"}>
          {/*###################### HEAD ######################*/}

          <div className={"row-between"}>
            <BT
              type="primary"
              style={{
                backgroundColor: color.dplus_primary,
                borderColor: color.dplus_primary,
              }}
              onClick={this.downloadExcel}
              loading={loading}
            >
              Export excel
            </BT>
            <BT
              type="primary"
              style={{
                backgroundColor: color.dplus_secondary,
                borderColor: color.dplus_secondary,
              }}
              onClick={this.onRefreshClick}
              loading={loading}
            >
              Refresh
            </BT>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              width: "100%",
              alignItems: "center",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Input.Search
              style={{
                width: "100%",
                borderRadius: "10vw",
              }}
              placeholder={"ค้นหาข้อมูลทั้งหมด"}
              value={fullTextSearch}
              onKeyPress={(e) => this.onKeyPressSearch(e, "fullTextSearch")}
              onChange={(e) => this.onChange(e.target.value, "fullTextSearch")}
            />
          </div>

          {/*###################### TABLE ######################*/}
          <Table
            title={() => <h2>Campaign iPhone</h2>}
            columns={CampaignIphoneColumnTableWarranty(filters)}
            rowClassName={(record, i) => (i % 2 === 1 ? "even" : "")}
            dataSource={data}
            bordered
            pagination={{ pageSize: 50 }}
            loading={isLoadingData}
            size="small"
            scroll={{ x: 1500, y: "100%" }}
          />
        </div>
      </div>
    );
  }
}

export default CampaignIphoneScene;
