import React from "react";
import { BtnConfirm } from "../components/Button";
import { Tag, Icon } from "antd";
import {
  ColumnStatus,
  ColumnStatus_FOCUS,
  ColumnStatus_ImportAx_Focus,
} from "../components/Table";
import FocusClaimScene from "../scene/Claim/ClaimScene";
import DetailClaimScene from "../scene/Claim/DetailClaimScene";
import EditClaimScene from "../scene/Claim/EditClaimScene";
import MultiClaimScene from "../scene/MultiClaim/MultiClaimScene";
import RegisterWarrantyScene from "../scene/Warranty/RegisterWarranty";

import HistoryScene from "../scene/Claim/HistoryScene";
import WarrantyScene from "../scene/Warranty/WarrantyScene";
// import FocusResetLineScene from "../scene/user/ResetLineScene";
import UserScene from "../scene/user/UserScene";
import EditWarrantyScene from "../scene/Warranty/EditWarrantyScene";
// import ProductsScene from "../scene/Products/ProductsScene";

import CreateClaimScene from "../scene/Claim/CreateClaimScene";
import OrderListScene from "../scene/user/OrderListScene";
import PrivilegeScene from "../scene/Promotion/PrivilegeScene";
// import CouponScene from "../scene/Promotion/CouponScene";
import FrequencyCustomerScene from "../scene/Report/FrequencyCustomerScene";
import UsedCouponScene from "../scene/Report/UsedCouponScene";
import RegisterSuperMember from "../scene/Report/RegisterSuperMember";
import AllCouponScene from "../scene/Report/AllCouponScene";
import StoreScene from "../scene/Store/StoreScene";
import { EditOutlined } from "@ant-design/icons";
import CampaignIphoneScene from "../scene/Campaign/iphone/CampaignIphoneScene";

/*
export const menu = {
  admin: {
    title: "Ablemen Service",
    // role: "shop",
    defaultUrl: "/claim",
    menu: [
      {
        path: "/register-warranty",
        name: "ลงทะเบียนสินค้า",
        icon: "form",
        component: RegisterWarrantyScene,
      },
      {
        path: "/claim",
        name: "เคลมสินค้า",
        icon: "file-search",
        component: FocusClaimScene,
      },
      {
        path: "/multipost",
        name: "ยิงจ่ายพัสดุ",
        icon: "qrcode",
        component: MultiClaimScene,
      },
      {
        path: "/history",
        name: "ประวัติการเคลม",
        icon: "profile",
        component: HistoryScene,
      },
      {
        path: "/warranty",
        name: "รายงานการลงทะเบียนสินค้า",
        icon: "profile",
        component: WarrantyScene,
      },
      // {
      //   path: "/reset-lineid",
      //   name: "รีเซ็ตไอดีไลน์ลูกค้า",
      //   icon: "form",
      //   component: FocusResetLineScene,
      // },
      {
        path: "/user",
        name: "ข้อมูลลูกค้า",
        icon: "user",
        component: UserScene,
      },
      {
        path: "/order-list",
        name: "รายการสั่งซื้อ",
        icon: "shopping",
        component: OrderListScene,
      },
      {
        path: "/order-list",
        name: "รายงาน",
        icon: "shopping",
        subMenu: [{
          name: "Sale Order",
          icon: "shopping",
          path: "/order-list",

        }]
        // component: OrderListScene,
      },
    ],
    routes: [
      {
        index: 0,
        path: "/register-warranty",
        name: "ลงทะเบียนสินค้า",
        component: RegisterWarrantyScene,
      },
      {
        index: 1,
        path: "/claim",
        name: "เคลมสินค้าFocus",
        component: FocusClaimScene,
      },
      {
        index: 2,
        path: "/multipost",
        name: "ยิงจ่ายพัสดุ",
        component: MultiClaimScene,
      },
      {
        index: 3,
        path: "/history",
        name: "ประวัติการเคลม",
        component: HistoryScene,
      },
      {
        index: 6,
        path: "/warranty",
        name: "รายงานการลงทะเบียนสินค้า",
        component: WarrantyScene,
      },
      {
        path: "/warranty_detail",
        name: "แก้ไขรับประกัน",
        component: EditWarrantyScene,
      },
      {
        path: "/billshipping",
      },
      {
        path: "/claim_detail",
        name: "รายละเอียด",
        component: DetailClaimScene,
      },
      {
        path: "/claim_edit",
        name: "แก้ไขรายการเคลมสินค้่า",
        component: EditClaimScene,
      },
      {
        path: "/claim_create",
        name: "ยื่นเคลม",
        component: CreateClaimScene,
      },
      // {
      //   path: "/reset-lineid",
      //   name: "รีเซ็ตไอดีไลน์ลูกค้า",
      //   component: FocusResetLineScene,
      // },
      {
        path: "/products",
        name: "รายการสินค้ารับประกัน",
        component: ProductsScene,
      },
      {
        path: "/user",
        name: "ข้อมูลลูกค้า",
        component: UserScene,
      },
      {
        path: "/order-list",
        name: "รายการสั่งซื้อ",
        component: OrderListScene,
      },
      {
        path: "/order-list",
        name: "รายการสั่งซื้อ",
        children: [{
          path: "/order-list",
          name: "รายงาน",
          icon: "shopping",
          component: OrderListScene,
        }]
      },
    
    ]
  }
};
*/

export const menu = [
  {
    show: true,
    path: "/register-warranty",
    name: "ลงทะเบียนสินค้า",
    icon: "form",
    component: RegisterWarrantyScene,
  },
  {
    show: true,
    path: "/claim",
    name: "เคลมสินค้า",
    icon: "file-search",
    component: FocusClaimScene,
  },
  {
    show: false,
    path: "/claim_edit",
    name: "แก้ไขรายการเคลมสินค้่า",
    component: EditClaimScene,
  },
  {
    path: "/claim_create",
    name: "ยื่นเคลม",
    component: CreateClaimScene,
  },
  {
    show: false,
    path: "/claim_detail",
    name: "รายละเอียด",
    component: DetailClaimScene,
  },
  {
    show: true,
    path: "/multipost",
    name: "ยิงจ่ายพัสดุ",
    icon: "qrcode",
    component: MultiClaimScene,
  },
  {
    show: true,
    path: "/history",
    name: "ประวัติการเคลม",
    icon: "profile",
    component: HistoryScene,
  },
  {
    show: true,
    name: "รายการลงทะเบียน",
    icon: "profile",
    path: "/warranty",
    component: WarrantyScene,
  },
  {
    show: false,
    path: "/warranty_detail",
    name: "แก้ไขรับประกัน",
    component: EditWarrantyScene,
  },
  {
    show: true,
    path: "/order-list",
    name: "รายการสั่งซื้อ",
    icon: "shopping",
    component: OrderListScene,
  },
  {
    show: true,
    path: "/user",
    name: "ข้อมูลลูกค้า",
    icon: "user",
    component: UserScene,
  },
  {
    show: true,
    path: "/privilege",
    name: "สิทธิพิเศษ",
    icon: "tags",
    component: PrivilegeScene,
  },
  {
    show: true,
    path: "/store",
    name: "ร้านค้า",
    icon: "shop",
    component: StoreScene,
  },
  {
    show: true,
    path: "/",
    name: "รายงาน",
    icon: "project",
    subMenu: [
      {
        name: "สมัครเมมเบอร์",
        icon: "crown",
        path: "/report-member",
        component: RegisterSuperMember,
      },
      {
        name: "คูปองทั้งหมด",
        icon: "tags",
        path: "/report-all-coupon",
        component: AllCouponScene,
      },
      {
        name: "ใช้คูปอง",
        icon: "tags",
        path: "/report-used-coupon",
        component: UsedCouponScene,
      },
      {
        name: "ความถี่",
        icon: "box-plot",
        path: "/report-frequency",
        component: FrequencyCustomerScene,
      },
    ],
  },
  {
    show: true,
    path: "/campaign/iphone",
    name: "Campaign Iphone 16",
    icon: "shop",
    component: CampaignIphoneScene,
  },
];

export const columnTableClaim = (fn = () => {}, onClick = () => {}) => [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
    width: "2vw",
    className: "td-content",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
    sorter: (a, b) => a.no - b.no,
  },
  {
    title: "เลขที่เคลม",
    dataIndex: "claim_id",
    align: "center",
    className: "td-content",
    width: "10vh",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
    sorter: (a, b) =>
      a.claim_id < b.claim_id ? -1 : a.claim_id > b.claim_id ? 1 : 0,
  },
  {
    title: "Line Name",
    dataIndex: "line_name",
    align: "center",
    className: "td-content",
    width: "8vw",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
  },
  {
    title: "ชื่อ-นามสกุล",
    dataIndex: "name",
    align: "center",
    className: "td-content",
    width: "9vw",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
  },
  {
    title: "เบอร์โทรศัพท์",
    dataIndex: "telephone",
    align: "center",
    className: "td-content",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
  },
  {
    title: "วันที่แจ้ง",
    dataIndex: "create_at",
    align: "center",
    width: "5vw",
    className: "td-content",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
    sorter: (a, b) =>
      a.create_at < b.create_at ? -1 : a.create_at > b.create_at ? 1 : 0,
  },
  {
    title: "ชื่อสินค้า",
    dataIndex: "product_name",
    align: "center",
    className: "td-content",
    width: "8vw",
    onCell: (record, i) => ({
      onClick: () => fn(record),
      style: {
        whiteSpace: "wrap",
        maxWidth: "8vw",
      },
    }),
    render: (text) => {
      return (
        <div style={{ fontSizeAdjust: 1 }}>
          <font className={"font-table"}>{text}</font>
        </div>
      );
    },
  },
  {
    title: "วันคงเหลือ",
    dataIndex: "time_warranty",
    align: "center",
    className: "td-content",
    width: "2vw",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
    sorter: (a, b) =>
      a.time_warranty < b.time_warranty
        ? -1
        : a.time_warranty > b.time_warranty
        ? 1
        : 0,
  },
  {
    title: "สถานะ",
    dataIndex: "status",
    align: "center",
    className: "td-content td-content-confirm",
    width: "7vw",
    render: (status) => <ColumnStatus status={status} />,
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
    filters: [
      {
        text: "รอรับเรื่อง",
        value: 0,
      },
      {
        text: "ดำเนินการ",
        value: 1,
      },
      {
        text: "จัดส่งเรียบร้อย",
        value: 2,
      },
      {
        text: "ไม่อนุมัติ",
        value: 99,
      },
    ],
    onFilter: (value, record) => +record.status === +value,
  },
  {
    title: "Confirm EMS",
    dataIndex: "confirm_ems",
    align: "center",
    className: "td-content",
    width: "7vw",
    render: (text, record) =>
      text === "กรอกเลขพัสดุ" ? (
        <BtnConfirm title={text} onClick={() => onClick(record, 0)} />
      ) : (
        text
      ),
    onCell: (record, i) => ({
      onClick:
        record.shipping && record.shipping.ems
          ? () => onClick(record, 1)
          : null,
    }),
  },
  {
    title: "ส่งสินค้า",
    dataIndex: "shipping_date",
    align: "center",
    className: "td-content",
    width: "6vw",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
    sorter: (a, b) =>
      a.shipping_date < b.shipping_date
        ? -1
        : a.shipping_date > b.shipping_date
        ? 1
        : 0,
  },
  {
    title: "หมายเหตุ",
    dataIndex: "note",
    align: "center",
    className: "td-content",
    width: "7vw",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
  },
];

export const columnTableClaimFocus = (
  fn = () => {},
  onClick = () => {},
  onModalMessage = () => {},
  //onModalDetail = () =>{ },
  onModalNote = () => {},
  onModalStatusInternal = () => {},
  expanded
) => [
  {
    title: "ส่งข้อความ",
    width: 50,
    align: "center",
    // fixed: 'right',
    render: (text, record) => (
      <Icon
        type="message"
        style={{ fontSize: "1.5em" }}
        onClick={() => onModalMessage(record)}
      />
    ),
  },
  {
    title: "เลขที่เคลม",
    dataIndex: "no_claim",
    align: "center",
    className: "td-content",
    width: "80px",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
    sorter: (a, b) =>
      a.no_claim < b.no_claim ? -1 : a.no_claim > b.no_claim ? 1 : 0,
  },

  {
    title: "ชื่อ-นามสกุล",
    dataIndex: "name",
    align: "left",
    className: "td-content",
    width: "200px",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
  },
  {
    title: "ชื่อไลน์",
    dataIndex: "line_name",
    align: "left",
    className: "td-content",
    width: "200px",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
  },
  {
    title: "เบอร์โทรศัพท์",
    dataIndex: "tel",
    align: "center",
    className: "td-content",
    width: "100px",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
  },
  {
    title: "วันที่แจ้ง",
    dataIndex: "dateclaim_formate",
    align: "center",
    width: "100px",
    className: "td-content",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
    sorter: (a, b) =>
      a.dateclaim < b.dateclaim ? -1 : a.dateclaim > b.dateclaim ? 1 : 0,
  },
  {
    title: "รหัสสินค้า",
    dataIndex: "item_code",
    align: "left",
    className: "td-content",
    width: "150px",
    onCell: (record, i) => ({
      onClick: () => fn(record),
      style: {
        whiteSpace: "wrap",
        maxWidth: "8vw",
      },
    }),
    render: (text) => {
      return (
        <div style={{ fontSizeAdjust: 1 }}>
          <font className={"font-table"}>{text}</font>
        </div>
      );
    },
  },
  {
    title: "ชื่อสินค้า",
    dataIndex: "nameproduct",
    align: "left",
    className: "td-content",
    width: "300px",
    onCell: (record, i) => ({
      onClick: () => fn(record),
      style: {
        whiteSpace: "wrap",
        maxWidth: "8vw",
      },
    }),
    render: (text) => {
      return (
        <div style={{ fontSizeAdjust: 1 }}>
          <font className={"font-table"}>{text}</font>
        </div>
      );
    },
  },
  {
    title: "รุ่นมือถือ",
    dataIndex: "modelmobile",
    align: "left",
    className: "td-content",
    width: "150px",
    onCell: (record, i) => ({
      onClick: () => fn(record),
      style: {
        whiteSpace: "wrap",
        maxWidth: "8vw",
      },
    }),
    render: (text) => {
      return (
        <div style={{ fontSizeAdjust: 1 }}>
          <font className={"font-table"}>{text}</font>
        </div>
      );
    },
  },
  {
    title: "สถานะ",
    dataIndex: "status",
    align: "center",
    className: "td-content td-content-confirm",
    width: "150px",
    render: (status, record) => (
      <ColumnStatus_FOCUS status={status} type={record.type} />
    ),
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
    filters: [
      {
        text: "รอการตรวจสอบ",
        value: 2,
      },
      {
        text: "กำลังเตรียมจัดส่ง",
        value: 3,
      },
      {
        text: "จัดส่งเรียบร้อย",
        value: 4,
      },
      {
        text: "ไม่อนุมัติ",
        value: 99,
      },
    ],
    onFilter: (value, record) => +record.status === +value,
  },
  {
    title: "สถานะภายใน",
    dataIndex: "status_internal",
    align: "center",
    className: "td-content td-content-confirm",
    width: "150px",

    // #2196f3 ฟ้า WAITING_REVIEW
    // #eecd5a เหลือง INCOMPLETE_INFO, NO_ITEM, OUT_OF_STOCK, PREPARING_SEND
    // #4ac54f เขียว SHIPPED
    // #ff5e5e แดง REJECT

    render: (text, record) => (
      <Tag
        color={
          ["WAITING_REVIEW"].includes(record.status_internal)
            ? "#2196f3"
            : [
                "WAITING_CHECK_LACK_ITEM",
                "INCOMPLETE_INFO",
                "NO_ITEM",
                "OUT_OF_STOCK",
                "PREPARING_SEND",
              ].includes(record.status_internal)
            ? "#eecd5a"
            : ["SHIPPED"].includes(record.status_internal)
            ? "#60D936"
            : ["REJECT", "SHIPPED_RETURN"].includes(record.status_internal)
            ? "#ff5e5e"
            : "#9e9e9e"
        }
        onClick={() => onModalStatusInternal(record)}
      >
        {record.status_internal_name || "NO STATUS"}
      </Tag>
    ),
    filters: [
      {
        text: "รอตรวจสอบแจ้งเคลม",
        value: "WAITING_REVIEW",
      },
      {
        text: "รอตรวจสอบ ของขาด",
        value: "WAITING_CHECK_LACK_ITEM",
      },
      {
        text: "รอตรวจสอบข้อมูลไม่ครบ",
        value: "INCOMPLETE_INFO",
      },
      {
        text: "กำลังเตรียมพัสดุ ไม่มีของ",
        value: "NO_ITEM",
      },
      {
        text: "กำลังเตรียมพัสดุ ของขาด",
        value: "OUT_OF_STOCK",
      },
      {
        text: "กำลังเตรียมพัสดุ",
        value: "PREPARING_SEND",
      },
      {
        text: "จัดส่งเรียบร้อย",
        value: "SHIPPED",
      },
      {
        text: "จัดส่งเรียบร้อย ของตีกลับ",
        value: "SHIPPED_RETURN",
      },
      {
        text: "ไม่อนุมัติ",
        value: "REJECT",
      },
    ],
    onFilter: (value, record) => record.status_internal === value,
  },
  {
    title: "เลขพัสดุ",
    dataIndex: "confirm_ems",
    align: "center",
    className: "td-content",
    width: "150px",
    render: (text, record) =>
      record.type === "STORE" ? (
        ""
      ) : text === "กรอกเลขพัสดุ" ? (
        <BtnConfirm title={text} onClick={() => onClick(record, 0)} />
      ) : (
        text
      ),

    onCell: (record, i) => ({
      onClick: record.tracking ? () => onClick(record, 1) : null,
    }),
  },
  {
    title: "วันที่จัดส่ง",
    dataIndex: "shipping_formate",
    align: "center",
    className: "td-content",
    width: "100px",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
    sorter: (a, b) =>
      a.shipping_date < b.shipping_date
        ? -1
        : a.shipping_date > b.shipping_date
        ? 1
        : 0,
  },
  {
    dataIndex: "note",
    title: "หมายเหตุ",
    width: "80px",
    align: "center",
    render: (text, record) =>
      !text ? (
        <EditOutlined
          style={{ fontSize: "1.5em" }}
          onClick={() => onModalNote(record)}
        />
      ) : (
        <p onClick={() => onModalNote(record)}>{text}</p>
      ),
  },
];

export const columnTableAccounting = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
    width: "2vw",
    className: "td-content",
    sorter: (a, b) => a.no - b.no,
  },
  {
    title: "วันที่โอน",
    dataIndex: "date_pay",
    align: "center",
    width: "5vw",
    className: "td-content",
    sorter: (a, b) =>
      a.dateclaim < b.dateclaim ? -1 : a.dateclaim > b.dateclaim ? 1 : 0,
  },
  {
    title: "เลขที่เคลม",
    dataIndex: "no_claim",
    align: "center",
    className: "td-content",
    width: "10vh",
  },

  {
    title: "ชื่อ-นามสกุล",
    dataIndex: "name",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
  {
    title: "เบอร์โทรศัพท์",
    dataIndex: "tel",
    align: "center",
    className: "td-content",
    width: "6vw",
  },
  {
    title: "จำนวนเงิน",
    dataIndex: "money_pay",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
  {
    title: "รหัสสินค้า",
    dataIndex: "item_code",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
];

export const columnTableImportAx = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
    width: "2vw",
    className: "td-content",
    sorter: (a, b) => a.no - b.no,
  },
  {
    title: "วันที่แจ้งเคลม",
    dataIndex: "dateclaim_formate",
    align: "center",
    width: "5vw",
    className: "td-content",
    sorter: (a, b) =>
      a.dateclaim < b.dateclaim ? -1 : a.dateclaim > b.dateclaim ? 1 : 0,
  },
  {
    title: "ส่งสินค้า",
    dataIndex: "shipping_formate",
    align: "center",
    width: "6vw",
    className: "td-content",
    sorter: (a, b) =>
      a.dateclaim < b.dateclaim ? -1 : a.dateclaim > b.dateclaim ? 1 : 0,
  },
  {
    title: "เลขที่เคลม",
    dataIndex: "no_claim",
    align: "center",
    className: "td-content",
    width: "10vh",
  },
  {
    title: "ชื่อสินค้า",
    dataIndex: "nameproduct",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
  {
    title: "ยี่ห้อมือถือ",
    dataIndex: "brandmobile",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
  {
    title: "รุ่นมือถือ",
    dataIndex: "modelmobile",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
  {
    title: "รหัสสินค้า",
    dataIndex: "item_code",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
  {
    title: "เลขเอกสารจาก AX",
    dataIndex: "import_doc",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
  {
    title: "สถานะการ Import",
    dataIndex: "import_status",
    align: "center",
    className: "td-content td-content-confirm",
    width: "7vw",
    render: (import_status) => (
      <ColumnStatus_ImportAx_Focus status={import_status} />
    ),

    filters: [
      {
        text: "รอการดำเนินการ",
        value: 99,
      },
      {
        text: "Importสำเร็จ",
        value: 1,
      },
      {
        text: "Importไม่สำเร็จ",
        value: 2,
      },
    ],
    onFilter: (value, record) => +record.import_status === +value,
  },
];

export const columnTableReport = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
  },
  {
    title: "รายการสินค้า",
    dataIndex: "product_name",
    align: "center",
    width: "30%",
  },
  {
    title: "รูปสินค้า",
    dataIndex: "product_image",
    align: "center",
    render: (url) => (
      <img src={url} style={{ width: "2cm", height: "3cm" }} alt={""} />
    ),
  },
  {
    title: "จำนวน",
    dataIndex: "count",
    align: "center",
  },
];
export const columnTableReportFocus = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
  },
  {
    title: "รายการสินค้า",
    dataIndex: "product_name",
    align: "center",
    width: "30%",
  },
  {
    title: "รูปสินค้า",
    dataIndex: "product_image",
    align: "center",
    render: (url) => (
      <img src={url} style={{ width: "2cm", height: "3cm" }} alt={""} />
    ),
  },
  {
    title: "จำนวน",
    dataIndex: "count",
    align: "center",
  },
];

export const columnTableHistory = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
  },
  {
    title: "Line Name",
    dataIndex: "line_name",
    align: "center",
    // width: '30%'
  },
  {
    title: "ชื่อ-นามสกุล",
    dataIndex: "name",
    align: "center",
  },
  {
    title: "เบอร์โทร",
    dataIndex: "telephone",
    align: "center",
  },
  {
    title: "รหัสสินค้าลงทะเบียน",
    dataIndex: "product_register",
    align: "center",
    sorter: (a, b) =>
      a.product_register < b.product_register
        ? -1
        : a.product_register > b.product_register
        ? 1
        : 0,
  },
  {
    title: "ชื่อสินค้า",
    dataIndex: "product_name",
    align: "center",
  },
  {
    title: "จำนวนการเคลม",
    dataIndex: "count",
    align: "center",
    sorter: (a, b) => (a.count < b.count ? -1 : a.count > b.count ? 1 : 0),
  },
  {
    title: "วันที่เคลมล่าสุด",
    dataIndex: "last_date",
    align: "center",
    sorter: (a, b) =>
      a.last_date < b.last_date ? -1 : a.last_date > b.last_date ? 1 : 0,
  },
];

export const FocuscolumnTableHistory = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
  },

  {
    title: "ชื่อ-นามสกุล",
    dataIndex: "name",
    align: "left",
  },
  {
    title: "เบอร์โทร",
    dataIndex: "telephone",
    align: "center",
  },

  {
    title: "จำนวนการเคลม",
    dataIndex: "count",
    align: "center",
    sorter: (a, b) => (a.count < b.count ? -1 : a.count > b.count ? 1 : 0),
  },
  {
    title: "วันที่เคลมล่าสุด",
    dataIndex: "last_date",
    align: "center",
    sorter: (a, b) =>
      a.last_date < b.last_date ? -1 : a.last_date > b.last_date ? 1 : 0,
  },
];

export const ColumnTableWarranty = (fn = () => {}, onClick = () => {}) => [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
    className: "td-content",
    width: "2vw",
    onCell: (data, i) => ({
      onClick: () => fn(data),
    }),
  },
  {
    title: "เลขที่ประกัน",
    dataIndex: "no_warranty",
    align: "center",
    className: "td-content",
    width: "7vw",
    onCell: (data, i) => ({
      onClick: () => fn(data),
    }),
  },
  {
    title: "ชื่อ-นามสกุล",
    dataIndex: "name",
    align: "left",
    className: "td-content",
    width: "10vw",
    onCell: (data, i) => ({
      onClick: () => fn(data),
    }),
  },
  {
    title: "ชื่อไลน์",
    dataIndex: "line_name",
    align: "center",
    width: "10vw",
    className: "td-content",
    onCell: (data, i) => ({
      onClick: () => fn(data),
    }),
  },
  {
    title: "รหัสสินค้า",
    dataIndex: "item_code",
    align: "left",
    className: "td-content",
    width: "15vw",
    onCell: (data, i) => ({
      onClick: () => fn(data),
    }),
  },
  {
    title: "ชื่อสินค้า",
    dataIndex: "nameproduct",
    align: "left",
    className: "td-content",
    width: "15vw",
    onCell: (data, i) => ({
      onClick: () => fn(data),
    }),
  },
  {
    title: "รหัสรับประกัน",
    dataIndex: "qrcode",
    align: "center",
    className: "td-content",
    width: "5vw",
    onCell: (data, i) => ({
      onClick: () => fn(data),
    }),
  },
  {
    title: "แบรน",
    dataIndex: "brandmobile",
    align: "center",
    className: "td-content",
    width: "5vw",
    onCell: (data, i) => ({
      onClick: () => fn(data),
    }),
  },
  {
    title: "รุ่น",
    dataIndex: "modelmobile",
    align: "center",
    className: "td-content",
    width: "10vw",
    onCell: (data, i) => ({
      onClick: () => fn(data),
    }),
  },
  {
    title: "ช่องทางการซื้อ",
    dataIndex: "namestore",
    align: "center",
    className: "td-content",
    width: "10vw",
    filters: [
      {
        text: "ร้านตัวแทนจำหน่าย",
        value: "ร้านตัวแทนจำหน่าย",
      },
      {
        text: "iStudio, Studio7, Jaymart",
        value: "iStudio, Studio7, Jaymart",
      },
      {
        text: "ร้าน Focus Shop",
        value: "ร้าน Focus Shop",
      },
      {
        text: "ช่องทางออนไลน์ที่ไม่ใช่ของแบรนด์",
        value: "ช่องทางออนไลน์ที่ไม่ใช่ของแบรนด์",
      },
      {
        text: "ผ่าน Website, Shopee, Lazada ของแบรนด์",
        value: "ผ่าน Website, Shopee, Lazada ของแบรนด์",
      },
    ],
    onFilter: (value, record) => record.namestore === value,
    onCell: (data, i) => ({
      onClick: () => fn(data),
    }),
  },
  {
    title: "สถานะ",
    dataIndex: "status",
    align: "center",
    className: "td-content td-content-confirm",
    width: "7vw",
    render: (status, record) => (
      <ColumnStatus_FOCUS status={status} type={"COMPANY"} />
    ),

    filters: [
      {
        text: "ลงทะเบียน",
        value: 0,
      },
      {
        text: "รอชำระเงิน",
        value: 1,
      },
      {
        text: "รอการตรวจสอบ",
        value: 2,
      },
      {
        text: "เตรียมจัดส่ง",
        value: 3,
      },
      {
        text: "จัดส่งเรียบร้อย",
        value: 4,
      },
      {
        text: "ไม่อนุมัติ",
        value: 99,
      },
    ],
    onFilter: (value, record) => +record.status === +value,
  },

  {
    title: "เบอร์โทร",
    dataIndex: "telephone",
    align: "center",
    className: "td-content",
    width: "3vw",
    onCell: (data, i) => ({
      onClick: () => fn(data),
    }),
  },
  {
    title: "รับประกัน",
    dataIndex: "warranty_type_desc",
    align: "center",
    className: "td-content",
    width: "5vw",
    render: (text, record) => {
      return (
        <Tag color={record.warranty_type === "NORMAL" ? "#007bff" : "#eecd5a"}>
          {text}
        </Tag>
      );
    },
    onCell: (data, i) => ({
      onClick: () => fn(data),
    }),
    sorter: (a, b) =>
      a.warranty_type_desc < b.warranty_type_desc
        ? -1
        : a.warranty_type_desc > b.warranty_type_desc
        ? 1
        : 0,
  },
  {
    title: "วันที่ลงทะเบียน",
    dataIndex: "create",
    align: "center",
    className: "td-content",
    width: "3vw",
    onCell: (data, i) => ({
      onClick: () => fn(data),
    }),
    sorter: (a, b) => (a.create < b.create ? -1 : a.create > b.create ? 1 : 0),
  },
  {
    title: "วันหมดอายุ",
    dataIndex: "dateexp",
    align: "center",
    className: "td-content",
    width: "3vw",
    onCell: (data, i) => ({
      onClick: () => fn(data),
    }),
    sorter: (a, b) => (a.create < b.create ? -1 : a.create > b.create ? 1 : 0),
    render: (text, record) => {
      return <>{record.warranty_type === "NORMAL" ? text : "♾️"}</>;
    },
  },
  {
    title: "วันคงเหลือ",
    dataIndex: "time_warranty",
    align: "center",
    className: "td-content",
    width: "3vw",
    onCell: (data, i) => ({
      onClick: () => fn(data),
    }),
    sorter: (a, b) =>
      a.time_warranty < b.time_warranty
        ? -1
        : a.time_warranty > b.time_warranty
        ? 1
        : 0,
    render: (text, record) => {
      return <>{record.warranty_type === "NORMAL" ? text : "♾️"}</>;
    },
  },
];

export const KEY_BILL = "KqkTxGXzw1";

export const STATUS_CLAIM = [
  {
    status_id: 0,
    status_name: "รอรับเรื่อง",
  },
  {
    status_id: 1,
    status_name: "กำลังดำเนินการ",
  },
  {
    status_id: 2,
    status_name: "จัดส่งเรียบร้อย",
  },
  {
    status_id: 99,
    status_name: "ไม่อนุมัติ",
  },
];
export const STATUS_CLAIM_FOCUS = [
  {
    status_id: 0,
    status_name: "ลงทะเบียน",
  },
  {
    status_id: 1,
    status_name: "รอชำระเงิน",
  },
  {
    status_id: 2,
    status_name: "รอการตรวจสอบ",
  },
  {
    status_id: 3,
    status_name: "กำลังเตรียมจัดส่ง",
  },
  {
    status_id: 4,
    status_name: "จัดส่งเรียบร้อย",
  },
  {
    status_id: 99,
    status_name: "ไม่อนุมัติ",
  },
];
export const STATUS_CLAIM_DEALER = [
  {
    status_id: 2,
    status_name: "รอรับบริการ",
  },
  {
    status_id: 3,
    status_name: "เคลมเสร็จเรียบร้อย",
  },
  {
    status_id: 4,
    status_name: "จัดส่งเรียบร้อย",
  },
];
export const STORE_FOCUS = [
  {
    original_name: "COMPANY",
    thai_name: "DPLUS",
  },
  {
    original_name: "STORE",
    thai_name: "ร้านค้าที่ร่วมรายการ",
  },
  {
    original_name: "ONLINE",
    thai_name: "Online",
  },
  {
    original_name: "FOCUS",
    thai_name: "โฟกัส shop",
  },
];
export const STATUS_IMPORTAX_FOCUS = [
  {
    status_id: 99,
    status_name: "รอการดำเนินการ",
  },
  {
    status_id: 1,
    status_name: "Importเข้าเรียบร้อย",
  },
  {
    status_id: 2,
    status_name: "Importไม่สำเร็จ",
  },
];

export const STATUS_STORE_CLAIM = [
  {
    status_id: 0,
    status_name: "COMPANY",
  },
  {
    status_id: 1,
    status_name: "STORE",
  },
  {
    status_id: 2,
    status_name: "",
  },
];
export const CUSTOMER_WARRANTY_FORM = [
  {
    fieldName: "tel",
    label: "เบอร์โทรศัพท์ :",
    type: "tel",
    value: "",
  },
  {
    fieldName: "fullname",
    label: "ชื่อ-นามสกุล :",
    type: "text",
    value: "",
  },
];
export const PRODUCTS_WARRANTY_FORM = [
  {
    fieldName: "qrcode",
    label: "รหัสรับประกันสินค้า :",
    type: "text",
    value: "",
  },
  {
    fieldName: "nameproduct",
    label: "ชื่อสินค้า :",
    type: "select",
    value: "",
  },
  {
    fieldName: "brandmobile",
    label: "ยี่ห้อมือถือ :",
    type: "select",
    value: "",
  },
  {
    fieldName: "modelmobile",
    label: "รุ่นมือถือ :",
    type: "select",
    value: "",
  },
  {
    fieldName: "namestore",
    label: "ช่องทางการซื้อ :",
    type: "select",
    value: "",
  },
  {
    fieldName: "warranty_type",
    label: "ประเภทรับประกัน:",
    type: "select",
    value: "",
  },
  {
    fieldName: "inv_image",
    label: "ใบเสร็จ:",
    type: "file",
    value: "",
  },
  {
    fieldName: "package_image",
    label: "รูปสินค้า:",
    type: "file",
    value: "",
  },
  // {
  //   fieldName: 'store_no',
  //   label: 'รหัสร้านค้า :',
  //   type:'text',
  //   value:''
  // },
  // {
  //   fieldName: 'store_name',
  //   label: 'ชื่อร้านค้า :',
  //   type:'text',
  //   value:''
  // },
];

export const PRODUCTS_WARRANTY_EC_FORM = [
  {
    fieldName: "qrcode",
    label: "รหัสรับประกันสินค้า :",
    type: "text",
    value: "",
  },
  {
    fieldName: "nameproduct",
    label: "ชื่อสินค้า :",
    type: "select",
    value: "",
  },
  {
    fieldName: "namestore",
    label: "ช่องทางการซื้อ :",
    type: "select",
    value: "",
  },
  {
    fieldName: "warranty_type",
    label: "ประเภทรับประกัน:",
    type: "select",
    value: "",
  },
  {
    fieldName: "inv_image",
    label: "ใบเสร็จ:",
    type: "file",
    value: "",
  },
  {
    fieldName: "package_image",
    label: "รูปสินค้า:",
    type: "file",
    value: "",
  },
];

export const FocuscolumnTableProducts = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
  },

  {
    title: "ชื่อสินค้า",
    dataIndex: "product_name",
    align: "left",
  },
  {
    title: "หมวดหมู่",
    dataIndex: "category",
    align: "center",
  },
  {
    title: "สถานะ",
    dataIndex: "product_status",
    align: "center",
    render: (product_status) => {
      return (
        <Tag color={product_status === "1" ? "#52c41a" : "#f5222d"}>
          {product_status === "1" ? "Active" : "noneActive"}
        </Tag>
      );
    },
  },
  {
    title: "",
    dataIndex: "edit_product",
    align: "center",
  },
];

export const FocusColumnTableDealer = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
  },
  {
    title: "QR ร้านค้า",
    dataIndex: "qrStore",
    align: "center",
  },
  {
    title: "รหัสร้านค้า",
    dataIndex: "store_no",
    align: "center",
  },
  {
    title: "ชื่อร้านค้า",
    dataIndex: "store_name",
    align: "center",
  },
  {
    title: "เบอร์โทร",
    dataIndex: "store_tel",
    align: "center",
  },
  {
    title: "สาขา",
    dataIndex: "branch_name",
    align: "center",
  },
  {
    title: "รหัส Sale",
    dataIndex: "sale_no",
    align: "center",
  },
  {
    title: "ประเภท",
    dataIndex: "type_store",
    align: "center",
    filters: [
      {
        text: "FOCUS",
        value: "FOCUS",
      },
      {
        text: "STORE",
        value: "STORE",
      },
    ],
    onFilter: (value, record) => record.type_store === value,
  },
  {
    title: "สถานะ",
    dataIndex: "store_status",
    align: "center",
    render: (store_status) => {
      return (
        <Tag color={store_status == "1" ? "#52c41a" : "#f5222d"}>
          {store_status == "1" ? "Active" : "Inactive"}
        </Tag>
      );
    },
    filters: [
      {
        text: "Inactive",
        value: 0,
      },
      {
        text: "Active",
        value: 1,
      },
    ],
    onFilter: (value, record) => +record.store_status === +value,
  },
  {
    title: "",
    dataIndex: "action",
    align: "center",
  },
];

export const UserColumnTable = (
  onModalEdit = () => {},
  onModalMessage = () => {}
) => [
  {
    rowKey: 1,
    title: "ลำดับ",
    dataIndex: "no",
    width: "5%",
    align: "center",
  },
  {
    rowKey: 2,
    title: "รหัสลูกค้า",
    dataIndex: "user_id",
    width: "10%",
    align: "center",
  },
  {
    rowKey: 3,
    title: "ชื่อ",
    dataIndex: "name",
    width: "25%",
    align: "left",
  },
  {
    rowKey: 4,
    title: "วันเกิด",
    dataIndex: "birthday",
    width: "10%",
    align: "center",
  },
  {
    rowKey: 5,
    title: "เบอร์โทร",
    dataIndex: "telephone",
    width: "10%",
    align: "center",
  },
  {
    rowKey: 6,
    title: "ไลน์",
    dataIndex: "line_name",
    width: "18%",
    align: "left",
  },
  {
    rowKey: 7,
    title: "กลุ่มลูกค้า",
    dataIndex: "level",
    width: "5%",
    align: "center",
    render: (text) => {
      return (
        <Icon
          type={text === "MEMBER" ? "crown" : "smile"}
          theme={text === "MEMBER" && "twoTone"}
          twoToneColor="#eecd5a"
          title={text}
          style={{ fontSize: "1.8em", fontWeight: "bold" }}
        />
      );
    },
  },
  {
    rowKey: 8,
    title: "แก้ไข",
    width: "5%",
    align: "center",
    render: (text, record) => (
      <Icon
        type="tool"
        style={{ fontSize: "1.5em" }}
        onClick={() => onModalEdit(record)}
      />
    ),
  },
  {
    rowKey: 9,
    title: "ส่งข้อความ",
    width: "80px",
    align: "center",
    render: (text, record) => (
      <Icon
        type="message"
        style={{ fontSize: "1.5em" }}
        onClick={() => onModalMessage(record)}
      />
    ),
  },
];

export const ColumnTableOrderList = (
  onEdit = () => {},
  clickImage = () => {}
) => [
  {
    title: "ลำดับ",
    dataIndex: "no",
    width: "5%",
    align: "center",
  },
  {
    title: "ช่องทาง",
    dataIndex: "channel_order_desc",
    width: "10%",
    align: "center",
  },
  {
    title: "เลขคำสั่งซื้อ",
    dataIndex: "inv_no",
    width: "20%",
    align: "center",
  },
  {
    title: "สินค้า",
    dataIndex: "description",
    width: "15%",
    align: "center",
  },
  {
    title: "เบอร์โทร",
    dataIndex: "telephone",
    width: "10%",
    align: "center",
  },
  {
    title: "วันที่ซื้อ",
    dataIndex: "order_date",
    width: "15%",
    align: "center",
  },
  {
    title: "สถานะ",
    dataIndex: "order_status",
    width: "10%",
    align: "center",
    render: (text, record) => {
      let color = "";
      switch (record.order_status) {
        case "WAIT_USE":
          color = "#FFCA33";
          break;
        case "USED":
          color = "#52c41a";
          break;
        case "CANCEL":
          color = "#f5222d";
          break;
        default:
          color = "#D1CFC9";
      }
      return <Tag color={color}>{record.order_status_desc}</Tag>;
    },
  },
  {
    title: "สลิปโอนเงิน",
    dataIndex: "inv_image",
    width: "5%",
    align: "center",
    render: (src) => {
      return (
        <img
          src={src}
          style={{ width: "1cm", height: "1cm" }}
          onClick={() => clickImage(src)}
        />
      );
    },
  },
  {
    title: "แก้ไข",
    width: "6%",
    align: "center",
    render: (text, record) => (
      <Icon
        type="tool"
        style={{ fontSize: "1.5em" }}
        onClick={() => onEdit(record)}
      />
    ),
  },
];

export const ColumnReportRegisterSuperMember = () => [
  {
    title: "ลำดับ",
    dataIndex: "no",
    width: "5%",
    align: "center",
  },
  {
    title: "ช่องทาง",
    dataIndex: "channel_order_desc",
    width: "10%",
    align: "center",
  },
  {
    title: "เลขคำสั่งซื้อ",
    dataIndex: "inv_no",
    width: "20%",
    align: "center",
  },
  {
    title: "สินค้า",
    dataIndex: "description",
    width: "15%",
    align: "center",
  },
  {
    title: "ชื่อลูกค้า",
    dataIndex: "name",
    width: "10%",
    align: "center",
  },
  {
    title: "เบอร์โทร",
    dataIndex: "telephone",
    width: "10%",
    align: "center",
  },
  {
    title: "วันที่ซื้อ",
    dataIndex: "order_date",
    width: "15%",
    align: "center",
  },
];

export const ColumnReportUsedCoupon = () => [
  {
    title: "ลำดับ",
    dataIndex: "no",
    width: "5%",
    align: "center",
  },
  {
    title: "ช่องทาง",
    dataIndex: "channel_used_coupon_desc",
    width: "10%",
    align: "center",
  },
  {
    title: "ชื่อคูปอง",
    dataIndex: "title",
    width: "20%",
    align: "center",
  },
  {
    title: "ประเภทคูปอง",
    dataIndex: "coupon_type_desc",
    width: "10%",
    align: "center",
  },
  {
    title: "รหัสคูปอง",
    dataIndex: "code",
    width: "10%",
    align: "center",
  },
  {
    title: "ชื่อลูกค้า",
    dataIndex: "name",
    width: "10%",
    align: "center",
  },
  {
    title: "เบอร์โทร",
    dataIndex: "telephone",
    width: "10%",
    align: "center",
  },
  {
    title: "วันที่ใช้",
    dataIndex: "used_date",
    width: "15%",
    align: "center",
  },
];

export const ColumnReportFrequencyCustomerScene = () => [
  {
    title: "ลำดับ",
    dataIndex: "no",
    width: "5%",
    align: "center",
  },
  {
    title: "ชื่อลูกค้า",
    dataIndex: "name",
    width: "35%",
    align: "center",
  },
  {
    title: "เบอร์โทร",
    dataIndex: "telephone",
    width: "15%",
    align: "center",
  },
  {
    title: "สถานที่",
    dataIndex: "channel_order_desc",
    width: "15%",
    align: "center",
  },
  {
    title: "จำนวน",
    dataIndex: "count_qty",
    width: "15%",
    align: "center",
  },
  {
    title: "วันใช้บริการล่าสุด",
    dataIndex: "latest_date",
    width: "15%",
    align: "center",
  },
];

export const ColumnPrivilegeScene = (onEdit = () => {}) => [
  {
    title: "ลำดับ",
    dataIndex: "no",
    width: "5%",
    align: "center",
  },
  {
    title: "สิทธิเศษ",
    dataIndex: "description",
    width: "70%",
    align: "left",
  },
  {
    title: "สถานะ",
    dataIndex: "status",
    width: "20%",
    align: "center",
    render: (text) => {
      return (
        <Tag color={text === "ACTIVE" ? "#52c41a" : "#ed6969"}>{text}</Tag>
      );
    },
  },
  // {
  //   title: "แก้ไข",
  //   width: "5%",
  //   align: "center",
  //   render: (text, record) => <Icon
  //               type='tool'
  //               style={{ fontSize: '1.5em'}}
  //               onClick={() => onEdit(record)}
  //           />
  // }
];

export const ColumnReportAllCoupon = () => [
  {
    title: "ลำดับ",
    dataIndex: "no",
    width: "5%",
    align: "center",
  },
  {
    title: "ชื่อคูปอง",
    dataIndex: "title",
    width: "25%",
    align: "left",
  },
  {
    title: "ชื่อคูปอง (EN)",
    dataIndex: "product_name",
    width: "25%",
    align: "left",
  },
  {
    title: "ประเภทคูปอง",
    dataIndex: "coupon_type_desc",
    width: "20%",
    align: "center",
  },
  {
    title: "จำนวนทั้งหมด",
    dataIndex: "qty",
    width: "10%",
    align: "center",
  },
  {
    title: "จำนวนที่ใช้ไป",
    dataIndex: "used",
    width: "10%",
    align: "center",
  },
];

export const ColumnStoreScene = (onEdit = () => {}) => [
  {
    title: "ลำดับ",
    dataIndex: "no",
    width: "5%",
    align: "center",
  },
  {
    title: "รหัสร้านค้า",
    dataIndex: "store_no",
    width: "15%",
    align: "left",
  },
  {
    title: "ชื่อร้านค้า",
    dataIndex: "store_name",
    width: "20%",
    align: "left",
  },
  {
    title: "สาขา",
    dataIndex: "branch_name",
    width: "30%",
    align: "left",
  },
  {
    title: "บริษัท",
    dataIndex: "company",
    width: "10%",
    align: "left",
  },
  {
    title: "สถานะ",
    dataIndex: "status",
    width: "10%",
    align: "center",
    render: (text) => {
      return (
        <Tag color={text === "ACTIVE" ? "#52c41a" : "#ed6969"}>{text}</Tag>
      );
    },
  },
  {
    title: "แก้ไข",
    width: "5%",
    align: "center",
    render: (text, record) => (
      <Icon
        type="tool"
        style={{ fontSize: "1.5em" }}
        onClick={() => onEdit(record)}
      />
    ),
  },
];

export const CampaignIphoneColumnTableWarranty = (filters) => [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
    width: 60,
  },
  {
    title: "วันที่ลงทะเบียน",
    dataIndex: "createdDateTime",
    align: "left",
    width: 150,
    sorter: (a, b) =>
      a.createdDateTime < b.createdDateTime
        ? -1
        : a.createdDateTime > b.createdDateTime
        ? 1
        : 0,
  },
  {
    title: "ชื่อ-นามสกุล",
    dataIndex: "fullName",
    align: "left",
    width: 200,
    sorter: (a, b) =>
      a.fullName < b.fullName ? -1 : a.fullName > b.fullName ? 1 : 0,
  },
  {
    title: "เบอร์โทรศัพท์",
    dataIndex: "phoneNumber",
    align: "left",
    width: 140,
    sorter: (a, b) =>
      a.phoneNumber < b.phoneNumber
        ? -1
        : a.phoneNumber > b.phoneNumber
        ? 1
        : 0,
  },
  {
    title: "รหัสร้านค้า",
    dataIndex: "storeCode",
    align: "left",
    width: 150,
    sorter: (a, b) =>
      a.storeCode < b.storeCode ? -1 : a.storeCode > b.storeCode ? 1 : 0,
  },
  {
    title: "ร้านค้า",
    dataIndex: "storeName",
    align: "left",
    width: 250,
    sorter: (a, b) =>
      a.storeName < b.storeName ? -1 : a.storeName > b.storeName ? 1 : 0,
  },
  {
    title: "รุ่นมือถือ",
    dataIndex: "modelName",
    align: "left",
    width: 160,
    sorter: (a, b) =>
      a.modelName < b.modelName ? -1 : a.modelName > b.modelName ? 1 : 0,
  },
  {
    title: "รหัสสินค้า",
    dataIndex: "productCode",
    align: "left",
    width: 160,
    sorter: (a, b) =>
      a.modelName < b.modelName ? -1 : a.modelName > b.modelName ? 1 : 0,
  },
];
